/* You can add global styles to this file, and also import other style files */
@import "src/assets/scss/config/default/bootstrap.scss";
@import "src/assets/scss/config/default/app.scss";
@import "src/assets/scss/icons.scss";

@import url("../node_modules/primeicons/primeicons.css");
@import url("../node_modules/primeng/resources/themes/lara-light-blue/theme.css");
@import url("../node_modules/primeng/resources/primeng.min.css");

.spinner-border {
    position: absolute;
    left: calc(50% - 2.5rem);
    top: 50%;
    z-index: 100;
    width: 5rem !important;
    height: 5rem !important;
    border-width: 4px !important;
}
.center {
    justify-content: center;
}

.padding-top {
    padding-top: 10px;
}

/* modal animation */
/*
.modal-content {
    animation-name: zoom-in;
    animation-duration: .3s;
}

@keyframes zoom-in {
    0% {transform: scale(0)}
    100% {transform: scale(1)}
}
*/

/* Google Maps autocomplete div should has higher z-index than any modal */
.pac-container { z-index: 10000 !important; width: auto !important; }

/* Remove transitions to remove flickering in Safari */
#topnav-hamburger-icon, #page-topbar, .navbar-menu .pricing-plan {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.text-align-right {
    text-align: right;
}

.text-align-center {
    text-align: center !important;
}

* {
    letter-spacing: 0.5px;
}

.search-box {
    width: 20.5em;
}

.no-wrap {
    white-space: nowrap;
}

.search  {
    font-family: 'Poppins', sans-serif;
}

.clickable {
    text-decoration: underline !important;
    color: #405189;
}

.p-component {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
}

.alert-success {
    color: #088675;
    background-color: #daf4f0;
    border-color: #b6e8e1;
    text-align: center;
}

.opacity-0 {
    opacity: 0;
}

.user-form-label {
    padding-left: 0px;
}

.marker-position {
    top: 35px;
    position: relative;
}

.no-select {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* The rule below is not implemented in browsers yet */
  -o-user-select: none;

  /* The rule below is implemented in most browsers by now */
  user-select: none;
}

.can-select {
  -webkit-user-select: text; /* Chrome/Safari */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* IE10+ */

  /* The rule below is not implemented in browsers yet */
  -o-user-select: text;

  /* The rule below is implemented in most browsers by now */
  user-select: text;
}

.privacy-link {
    text-decoration: underline !important;
    z-index: 3;
    color: var(--vz-footer-color);
    cursor: pointer !important;
}

.pdf-page {
    top: 70px;
    left: 250px;
    width: calc(100% - 250px);
    overflow-x: hidden;
    height: calc(100vh - 130px);
    position: absolute;
    display: inline-block;
}


// custom ngb modal class styles
.full-page-modal-container {
    margin: 5px;
    max-width: 100%;

    .modal-content {
        height: calc(100vh - 10px); // 10px = left&right margins
        overflow: auto;
    }
}
.almost-full-page-modal-container {
    margin: 5px;
    max-width: 90%;
    left: 5%;

    .modal-content {
        height: calc(90vh - 10px); // 10px = left&right margins
        overflow: auto;
    }
}
.modal-on-modal-backdrop {
    z-index: $zindex-modal !important;  
}

@media only screen and (max-width: 1350px) {
    .page-content {
        padding: calc(120px + 1.5rem) .75rem 60px .75rem;
    }

    .navbar-header {
        height: 120px;
    }

    .selection-dropdown {
        flex-direction: column;
        margin: 1em;
        align-items: flex-end !important;
    }

    .branch-selection {
        margin-left: 0em !important;
    }

    .selection {
        margin-bottom: .5em;
    }

    // .selection-dropdown {
    //     padding: 0 17em;
    // }

    .topbar-user {
        background-color: #52639c !important;
        height: 100%;
    }

    .name-box {
        height: 100%;
    }

    
}

@media screen and (max-width: 767px) {
    input, select, textarea {
         font-size: 18px !important;
    }
}

@media only screen and (max-width: 575px) {
    .page-title-box {
        height: 5em !important;
    }

    .selection-dropdown {
        width: 100%;
    }
    
    .selection {
        width: 100% !important;
    }

    // :host ::ng-deep .p-treeselect .p-treeselect {
    //     width: 100% !important;
    // }


}